.navbar {
    border-radius:              $navbar-border-radius;
    height:                     $navbar-height;

    .nav-item{
        border-right:           1px solid darken($gray, 10%);
        background:             -webkit-linear-gradient($gray, $black); /* For Safari 5.1 to 6.0 */
        background:             -o-linear-gradient($gray, $black); /* For Opera 11.1 to 12.0 */
        background:             -moz-linear-gradient($gray, $black); /* For Firefox 3.6 to 15 */
        background:             linear-gradient($gray, $black); /* Standard syntax */
    }

    .nav-item:first-child {
        border-left:            1px solid darken($gray, 10%);
    }

    .navbar-brand {
        color:                  $white;
        font-weight:            900;
        background-color:       $brand-danger;
        padding:                5px 15px;
        margin-left:            -7px;
        @extend                 .rounded;
    }

    .navbar-brand:hover {
        background-color:       $brand-danger;
    }

    .icon-item {
        i, 
        span {
            margin-right:       5px;
            color:              darken($white, 30%);
        }
    }

    .dropdown-menu {
        margin-top:             6px;
        background-color:       $black;
        @extend                 .nav-item;

        a {
            color:              $white;
        }
        
        a:hover,
        a:hover i {
            color:              $black !important;
        }

        i {
            color:              $brand-yellow;
        }
    }
}

.navbar-profile {
    
    .btn-profile {
        padding:                0;
        margin:                 0;
        background-color:       $gray;
    }

    .btn-profile:hover {
        background-color:       $gray-light;
    }

    .btn-logout {
        margin:                 0 -5px 0 10px;
        font-size:              1.7rem;

        i,
        span {
            color:              $white !important;
            margin-right:       -1px;
        }
    }
}