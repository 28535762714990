// --------------------------------------------------
// Buttons sizes
// --------------------------------------------------

.btn {
  cursor:                   pointer;
}

.btn-circle {
  border-radius:            100%;
}

.btn-delete {
  @extend                   .btn-circle;
  width:                    40px;
  height:                   40px;
  padding:                  10px 10px 10px 11px;
  font-weight:              900;
}

.btn-icon {
  width:                    20px;
  height:                   20px;
  text-align:               center;
  padding:                  6px 5px 5px 7px;
  font-size:                1.3rem;
  line-height:              1.3;
  @extend                   .btn-circle;
}

.btn-inline {
  height:                   40px;
  text-align:               center;
  padding:                  8px;
  margin-top:               30px;
  margin-left:              10px;
  font-size:                1.3rem;
}

// --------------------------------------------------
// Buttons inverted colors
// --------------------------------------------------

// Button red
.btn-lred {
    color:                $brand-danger;
    background-color:     transparent;
    border-color:         $brand-danger;
}
.btn-lred:hover,
.btn-lred:focus {
    color:                $white;
    background-color:     $brand-danger;
    border-color:         $brand-danger;
}

// Button pink
.btn-lpink {
    color:                $pink;
    background-color:     transparent;
    border-color:         darken($pink, 13.5%);
}
.btn-lpink:hover,
.btn-lpink:focus {
    color:                $white;
    background-color:     $pink;
    border-color:         $pink;
}

// Button purple
.btn-lpurple {
    color:                $purple;
    background-color:     transparent;
    border-color:         $purple;
}
.btn-lpurple:hover,
.btn-lpurple:focus {
    color:                $white;
    background-color:     $purple;
    border-color:         $purple;
}

// Button indigo (dark blue)
.btn-lindigo {
    color:                $indigo;
    background-color:     transparent;
    border-color:         $indigo;
}
.btn-lindigo:hover,
.btn-lindigo:focus {
    color:                $white;
    background-color:     $indigo;
    border-color:         $indigo;
}

// --------------------------------------------------
// Buttons colors
// --------------------------------------------------

// Button pink
.btn-pink {
    color:                $white;
    background-color:     $pink;
    border-color:         darken($pink, 13.5%);
}
.btn-pink:hover,
.btn-pink:focus {
    color:                $white;
    background-color:     darken($pink, 13.5%);
    border-color:         darken($pink, 13.5%);
}

// Button purple
.btn-purple {
    color:                $white;
    background-color:     $purple;
    border-color:         darken($purple, 13.5%);
}

.btn-purple:hover,
.btn-purple:focus {
    color:                $white;
    background-color:     darken($purple, 13.5%);
    border-color:         darken($purple, 13.5%);
}

// Button indigo (dark blue)
.btn-indigo {
    color:                $white;
    background-color:     $indigo;
    border-color:         darken($indigo, 13.5%);
}
.btn-indigo:hover, 
.btn-indigo:focus {
    color:                $white;
    background-color:     darken($indigo, 13.5%);
    border-color:         darken($indigo, 13.5%);
}

// Button indigo (dark blue)
.btn-gray {
    color:                $white;
    background-color:     $gray;
    border-color:         darken($gray, 13.5%);
}
.btn-gray:hover, 
.btn-gray:focus {
    color:                $white;
    background-color:     darken($gray, 13.5%);
    border-color:         darken($gray, 13.5%);
    box-shadow:           0 0 2px $gray !important;
}