.autocomplete-suggestions {
    text-align:             left; 
    cursor:                 default; 
    border:                 1px solid #ccc; 
    border-top:             0; 
    background:             $gray-lighter; 
    box-shadow:             -1px 1px 3px rgba(0,0,0,.1);

    /* core styles should not be changed */
    position:               absolute; 
    display:                none; 
    z-index:                9999; 
    max-height:             254px; 
    overflow:               hidden; 
    overflow-y:             auto; 
    box-sizing:             border-box;
}
.autocomplete-suggestion { 
    position:               relative; 
    padding:                5px 10px; 
    border-bottom:          1px solid $white;
    line-height:            30px; 
    white-space:            nowrap; 
    overflow:               hidden; 
    text-overflow:          ellipsis; 
    font-size:              1.1rem; 
    font-weight:            600;
    color:                  $gray; 
    display:                block;
    cursor:                 pointer;

    b {
        color:              $brand-danger;
        border-bottom:      1px solid $brand-danger;
    }

    .item-number {
        color:              $brand-warning;
    }
}

.autocomplete-suggestion.selected { 
    background:             $gray;
    color:                  $white; 
}