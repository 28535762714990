.bootstrap-colorpalette {
    padding-left:               4px;
    padding-right:              4px;
    white-space:                normal;
    line-height:                1;
}

.bootstrap-colorpalette div {
    line-height:                0;
}

.bootstrap-colorpalette .btn-color {
    width:                      17px;
    height:                     17px;
    border:                     1px solid $white;
    margin:                     0;
    padding:                    0;
}

.bootstrap-colorpalette .btn-color:hover {
    border:                     1px solid $black;
    cursor:                     pointer;
}

#colorpalette {
    position:                   absolute; 
    z-index:                    999 !important;
    display:                    none;
    background-color:           $brand-primary;
    padding:                    5px;
}