/**
 * DataTables Custom style 
**/

.dataTable {
    
    .img-profile {
        max-width:              75px;
    }
    
    tfoot {
        tr {
            border-top:         1px solid #000 !important;

            th {
                input,
                select {
                    width:      100% !important;
                    color:      blue;
                }
            }
        }
    }
}

.dataTables_processing  {
    position:                   absolute;
    top:                        50%;
    left:                       50%;
    width:                      250px;
    height:                     80px;
    margin-left:                -125px;
    margin-top:                 -15px;
    padding:                    15px 0 30px 0;
    border:                     1px solid #ddd;
    text-align:                 center;
    color:                      #444;
    font-size:                  14px;
    background:                 url("../../../img/loading.gif") no-repeat center 38px #fff;
    box-shadow:                 2px 2px 5px #444;
    -moz-box-shadow:            2px 2px 5px #444;
    -webkit-box-shadow:         2px 2px 5px #444;
    z-index:                    9999;
}

#dataTableBuilder_info {
    float:                      left;
    margin-left:                10px;
}

/**
 * DataTables Buttons
**/

//Buttons
.btn-dataTable {
    color:                      $white !important;
    padding:                    5px 5px 5px 10px;
    margin:                     2px;
    cursor:                     pointer;
}

.btn-excel {
    background:                 $brand-success !important;
}

.btn-excel:hover {
    background:                 darken($brand-success, 15%) !important;
}

.btn-print {
    background:                 $brand-info !important;
}

.btn-print:hover {
    background:                 darken($brand-info, 15%) !important;
}

.btn-pdf {
    background:                 $brand-danger !important;
}

.btn-pdf:hover {
    background:                 darken($brand-danger, 15%) !important;
}

.btn-csv {
    background:                 $brand-warning !important;
}

.btn-csv:hover {
    background:                 darken($brand-warning, 15%) !important;
}

.btn-reset {
    background:                 $gray !important;
    padding:                    5px 10px !important;
}

.btn-reset:hover {
    background:                 darken($gray, 15%) !important;
}

// --------------------------------------------------
// Buttons align
// --------------------------------------------------

/**
 * DataTables search fields
**/
#dataTableBuilder_wrapper {
    @extend                     .justify-content-between;
}

.datatables_button_container {
}

.datatables_length_container {
    @extend                     .mx-2;

    input,
    select {
        margin:                 0 0 0 10px;
        background:             $brand-yellow;
    }
}

.pagination {
    margin-top:                 10px !important;
}

.page-item {
    margin:                     1px 2px;
}