body {
    font-family: "Helvetica Neue", "Calibri Light", Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:visited {
    outline:                none !important;
    border:                 0 !important;
    text-decoration:        none;
}