.modal-colored-helper {
    padding:                            9px 15px;
    border-bottom:                      1px solid #eee;
    -webkit-border-top-left-radius:     5px;
    -webkit-border-top-right-radius:    5px;
    -moz-border-radius-topleft:         5px;
    -moz-border-radius-topright:        5px;
    border-top-left-radius:             5px;
    border-top-right-radius:            5px; 
}

.modal-footer {
    background-color:                   $gray-lighter;
}

.modal-success {
    color:                              $white;
    background-color:                   $brand-success;
    @extends .modal-colored-helper;
}

.modal-warning {
    color:                              $white;
    background-color:                   $brand-warning;
    @extends .modal-colored-helper;
}

.modal-danger {
    color:                              $white;
    background-color:                   $brand-danger;
    @extends .modal-colored-helper;
}

.modal-info {
    color:                              $white;
    background-color:                   $brand-info;
    @extends .modal-colored-helper;
}

.modal-primary {
    color:                              $white;
    background-color:                   $brand-primary;
    @extends .modal-colored-helper;
}

.close-light {
    color: $white; 
    opacity: 0.6;
}

.close-light:hover {
    color: $white; 
    opacity: 1;
}

//Modal effects when clicks
.modal-selected {
    color:                              $brand-danger;
    font-weight:                        900;
    border:                             2px solid $brand-danger !important;
}

.modal-click:hover
{
   cursor:                              pointer !important;
   background-color:                    $gray-custom !important;
}

//Modal Selected links 
.modal-list {
    padding:                            5px;

    a {
        color:                          $gray !important;
        font-size:                      1.2rem;
        padding:                        5px;
    }

    a:hover {
        color:                          $white !important;
        background-color:               $gray-light;
    }
}

//Modal prices 
#modal-list-prices {
    color:                                  darken($gray-custom, 20%);
    font-size:                              1.1rem;

    #service-prices-header {
        text-transform:                     capitalize;      
        margin-bottom:                      50px;

        h2 {
            margin:                         10px 0;
            text-align:                     left;
            font-size:                      1.25rem;

            span {
                float:                      right;
                color:                      $black;
            }                    
        }
    }

    .service-prices-item {
        padding:                            10px;
        border-bottom:                      1px solid $gray-lighter;

            span {
                float:                      right;
                color:                      $brand-danger;
                font-weight:                900;
            }   
    }

    .service-prices-item:nth-child(even) {
        background-color:                   $brand-yellow;
    }
}