// --------------------------------------------------
/////////////////////////////////////////////////////
// Variables
/////////////////////////////////////////////////////
// --------------------------------------------------

// --------------------------------------------------
// Colors
// --------------------------------------------------

$gray-base:             #000 !default;
$gray-darker:           lighten($gray-base, 13.5%) !default; // #222
$gray-dark:             lighten($gray-base, 20%) !default;   // #333
$gray:                  lighten($gray-base, 33.5%) !default; // #555
$gray-light:            lighten($gray-base, 46.7%) !default; // #777
$gray-custom:           lighten($gray-base, 80%) !default; // #eee
$gray-lighter:          lighten($gray-base, 95%) !default; // #eee
$white:                 #fff !default;
$black:                 #222 !default;
$purple:                #9b26af !default;
$indigo:                #3e50b4 !default;
$pink:                  #e81d62 !default;

$brand-primary:         darken(#0275b8, 6.5%) !default; // #337ab7
$brand-primary-hover:   darken($brand-primary, 10%) !default;
$brand-success:         #5cb85c !default;
$brand-success-hover:   darken($brand-success, 10%) !default;
$brand-info:            #5bc0de !default;
$brand-info-hover:      darken($brand-info, 10%) !default;
$brand-warning:         #f0ad4e !default;
$brand-warning-hover:   darken($brand-warning, 10%) !default;
$brand-danger:          #d9534f !default;
$brand-danger-hover:    darken($brand-danger, 10%) !default;
$brand-yellow:          #fcf8e3 !default;
$brand-yellow-hover:    darken($brand-yellow, 10%) !default;
$brand-brown:           #8a6d3b !default;
$brand-brown-hover:     darken($brand-brown, 10%) !default;

// --------------------------------------------------
// Navbar
// --------------------------------------------------
$navbar-border-radius:  0 !important;
$navbar-height:         60px;

// --------------------------------------------------
// Tables
// --------------------------------------------------
$table-thead-bg:        $brand-primary;
$table-thead-color:     $white;
$table-th-align:        center;
$table-td-align:        center;