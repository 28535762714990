.bg-primary {
    background-color:           $brand-primary !important;
}

.bg-success {
    background-color:           $brand-success !important;
}

.bg-warning {
    background-color:           $brand-warning !important;
}

.bg-danger {
    background-color:           $brand-danger !important;
}

.bg-info {
    background-color:           $brand-info !important;
}

.bg-gray {
    background-color:           $gray !important;
}

.bg-gray-lighter {
    background-color:           $gray-lighter;
}

.bg-purple {
    background-color:           $purple !important;
}

.bg-pink {
    background-color:           $pink !important;
}

.bg-yellow {
    background-color:           $brand-yellow;
}

.bg-indigo {
    background-color:           $indigo !important;
}

.bg-brown {
    background-color:           $brand-brown !important;
}