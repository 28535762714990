form {
    //Base fields
    legend {
        font-size:              1.4rem;  
        font-weight:            900;
        text-transform:         uppercase;
        margin:                 10px 0 20px 0;
        padding:                5px;
        background-color:       $gray-lighter;
        color:                  $gray-light;
    }

    legend.legend-required {
        color:                  $brand-danger;
        font-size:              0.9rem;
        font-weight:            400;
        border-bottom:          0;
        background-color:       transparent !important;
    }

    label {
        color:                  $gray;
        font-weight:            600;
    }

    //Required fields
    input,
    textarea,
    select {
        border:                 2px solid $gray-custom !important;
    }

    //Required fields
    input:required,
    textarea:required {
        border:                 2px solid $brand-danger !important;
        background-color:       $brand-yellow;
    }

    //Required fields
    select:required {
        border:                 2px solid $brand-danger !important;
        background-color:       darken($brand-yellow, 15%);
    }

    select {
        height:                 40px !important;
    }

    label.label-required {
        color:                  darken($brand-danger, 20%);
    }

    //Custom fields
    .label-required:after { 
        content:                "*";
        margin-left:            5px;
        top:                    5px;
    }

    .help-block {
        padding:                3px 5px;
        margin:                 3px 0;
        background-color:       $brand-danger;
        color:                  $white;
        @extend                 .rounded;
    }

    .input-group-addon{
        min-width:              50px !important;
    }

    .input-addon-required{
        @extend                 .input-addon-danger;
    }

    .input-addon-danger{
        border:                 1px solid $brand-danger;
        background-color:       $brand-danger;
        color:                  $white;
        font-weight:            900 !important;
    }

    .input-addon-warning{
        border:                 1px solid darken($brand-warning, 15%);
        background-color:       $brand-warning;
        color:                  $white;
        font-weight:            900 !important;
    }

    .input-addon-modal:hover{
        background-color:       darken($brand-danger, 15%);
        cursor:                 pointer;
        color:                  $white;
    }

    .date, 
    .year,
    .time,
    .number {
        text-align:             right;
    }

    .expand:focus,
    .expand-height:focus {
        z-index:                9999 !important;
        position:               absolute;
        box-shadow:             0 0 40px $brand-primary !important;
        border:                 1px solid $brand-primary !important;
    }

    .text-alert {
        margin:                 -10px 0 10px 18px !important;
    }
}