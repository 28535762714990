//Cards fields
.card-label {
    color:                  $white !important;
    font-weight:            600;
    font-size:              1rem;
}

.card-input {
    background-color:       $white !important;
    border:                 0 !important;
    color:                  $gray-light !important;
}

.card-input-group-addon {
    border:                 3px solid $gray-lighter;
    color:                  $gray-light;
}

//Cards search
.card-search {

    label {
        color:              $white !important;
        font-weight:        600;
        font-size:          1rem;
    }

    input {
        background-color:   $gray-lighter;
        color:              $brand-primary !important;
        font-weight:        600;
    }
}

.card-form {
    box-shadow:             5px 5px 20px $gray-custom;
}