/* tab color */
.nav-tabs > li > a {
  color:                    $gray;
  background-color:         lighten($gray-custom, 15%); 
  border:                   1px solid $gray-custom !important;
  margin:                   0 1px;
}

/* hover tab color */
.nav-tabs > li > a:hover {
  background-color:         $gray-light; 
  color:                    $white;
}

/* active tab color */
.nav-tabs > li > a.active, 
.nav-tabs > li > a.active:hover, 
.nav-tabs > li > a.active:focus {
  background-color:         $brand-danger !important; 
  border-color:             darken($brand-danger, 15%) !important;
  color:                    $white !important;
}