table {
    font-size:                      .75rem;
    @extend                         .w-100;
    box-shadow:                     5px 5px 20px $gray-custom;

    thead {
        background-color:           $table-thead-bg;
        color:                      $table-thead-color;
    }

    tfoot {
        background-color:           $gray-light;
        color:                      $white;
    }

    tr.selected {
        background-color: $brand-success !important;
        cursor: pointer;
        td {
            color: $white !important;
        }
    }

    th {
        text-align:                 $table-th-align;
    }
 
    td {
        text-align:                 $table-td-align;
        color:                      $black;
        padding:                    10px 3px !important;
        margin:                     0 !important;
        @extend                     .align-middle;
    }

    .table-danger,
    .table-success,
    .table-warning,
    .table-primary,
    .table-info {
        display:                    block;
        height:                     15px;
        margin:                     0;
        padding:                    0;
        color:                      $white;
        font-weight:                900;
        padding:                    2px !important;
    }

    .table-success-full,
    {
        display:                    block;
        background-color:           $brand-success;
        margin:                     0;
        padding:                    0;
        color:                      $white;
        font-weight:                900;
        padding:                    2px !important;
    }

    .table-danger {
        background-color:           $brand-danger;
    }

    .table-success {
        background-color:           $brand-success;
    }

    .table-warning {
        background-color:           $brand-warning;
    }

    .table-primary {
        background-color:           $brand-primary;
    }

    .table-info {
        background-color:           $brand-info;
    }

    .table-separator > td {
        border-bottom:              1px solid $gray-light;
    }
}

.table > tbody > tr > td,
.table th {
    box-shadow:                     1px 1px 1px $gray-custom;
}
