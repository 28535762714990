.img-thumbnail {
    max-width:              120px !important;
    max-height:             120px !important;
}

.img-profile {
    max-width:              25px;
    max-height:             25px;
    margin:                 0 10px 0 0;
    padding:                0;
}

#preview-container {
    overflow:               hidden;
    margin:                 0px;  
    padding:                5px;  
    background-color:       $gray;
    text-align:             center;
    @extend                 .rounded;

    #preview-title {
        font-size:          1.2rem;
        font-weight:        900;
        color:              $gray-lighter;
        text-transform:     uppercase;
        margin:             5px;
    }

    img {
       @extend              .rounded; 
    }
}