// --------------------------------------------------
// Breadcrumbs
// --------------------------------------------------

#breadcrumb-container {
    margin-top:                     25px;

    // --------------------------------------------------
    // Main class
    // --------------------------------------------------
    .breadcrumb {
        text-transform:             uppercase;
        margin:                     20px 5px;

        li {
            font-size:              1rem; 
            font-weight:            900;
        }
    }

    // --------------------------------------------------
    // Breadcrumbs buttons
    // --------------------------------------------------
    .btn-breadcrumb-conf {
        height:                     35px !important;
        margin:                     5px !important;
        margin:                     7px 15px -15px 0 !important;   
    }

    .btn-breadcrumb {
        @extend                     .float-right;

        i {
            color:                  $brand-yellow;
            text-shadow:            2px 2px 5px $black;
            margin-right:           5px;
        }

        .dropdown-menu {
            background:             -webkit-linear-gradient($gray, $black); /* For Safari 5.1 to 6.0 */
            background:             -o-linear-gradient($gray, $black); /* For Opera 11.1 to 12.0 */
            background:             -moz-linear-gradient($gray, $black); /* For Firefox 3.6 to 15 */
            background:             linear-gradient($gray, $black); /* Standard syntax */
            margin:                 18px 32px 0 -32px !important;
            @extend                 .rounded;
        }

        .dropdown-item-first,
        .dropdown-item-first:hover{
            color:                  $white;
            padding:                10px 20px !important;
        }

        .dropdown-item,
        .dropdown-item:hover,
        .dropdown-item:active {
            color:                  $white;
            border-bottom:          1px solid $black !important;
            padding:                5px 15px !important;
        }

        .dropdown-item:hover {
            color:                  $gray;
            background-color:       $white;

            i {
                color:              $gray;
                text-shadow:        none;
            }
        }
    }
}