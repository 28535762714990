/* centered columns */

.break {
    width:                  100%;
    clear:                  both;
}

.break-line {
    @extend                 .break;
    padding:                10px 15px; 
}

.break-butons {
    @extend                 .break;
    padding:                15px 15px; 
}

.hidden {
    display:                none !important;
    visibility:             hidden !important;
} 

.hide {
    display:                none !important;
}

.highlight {
    color:                  $brand-danger;
    font-weight:            900;
}

.float-right {
    float:                  right !important;
}

.float-left {
    float:                  left !important;
}

.loading {    
    background-color: #ffffff;
    background-image: url("../../../../img/loading.gif");
    background-size: 25px 25px;
    background-position: center center;
    background-repeat: no-repeat;
}

.loading-right {    
    background-color: #ffffff;
    background-image: url("../../../../img/loading.gif");
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
}